import React, { useState } from 'react';
import { Navigate } from 'react-router';
import Modal from 'react-modal';
import '../styles/Download1.css';

Modal.setAppElement('#root');

const Download1 = ({ data }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [media, setMedia] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);

  if (data === null || typeof data === 'undefined') {
    return <Navigate to="/login" />;
  }

  const handleButtonClick = (media, url) => {
    setMedia(media);
    setDownloadUrl(url);
    if (!media) {
      window.open(url, '_blank');
    } else {
      setModalOpen(true);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setMedia(null);
    setDownloadUrl(null);
  };

  return (
    <div className="App">
      <a href="/logout" className="logout-button">
        Déconnexion
      </a>
      <header className="App-header">
        <h1>Pack FiveM</h1>
      </header>
      <div className="App-body">
        <p>Cliquez sur les boutons ci-dessous pour télécharger le pack voulu :</p>
        <ul className="App-buttons">
          <li>
            <button onClick={() => handleButtonClick('https://cdn.discordapp.com/attachments/1085569148689592383/1126579818432053338/FiveM_PACK_GRAPHICS_CITIZEN_OPTIMIZED_300_FPS.mp4', 'https://mega.nz/file/5yZlhZYQ#QEqHFt47Sdi16YVmwO9ZxOfWIlVefrdWG5pJQiZDRQU')} className="App-button primary">
              Binto RP 300 FPS
            </button>
          </li>
          <li>
            <button onClick={() => handleButtonClick('https://cdn.discordapp.com/attachments/1085569148689592383/1126851223430123650/Desktop_2023.07.07_-_14.22.46.02.DVR_Slomo.mp4', 'https://mega.nz/file/wyQgHRRZ#1g18pKIABzoo6vTVT1zsHyss-rppSIAx-iSCquFKOHY')} className="App-button primary">
              Binto RP Opti
            </button>
          </li>
          <li>
            <button onClick={() => handleButtonClick('https://cdn.discordapp.com/attachments/1085569148689592383/1126853346750373969/Desktop_2023.07.07_-_14.31.21.03.DVR_Slomo.mp4', 'https://mega.nz/file/l6BjRbAA#XfU6t4DIxP_TfSPnV_OqOzwwrLnlXA8Ny5Qzm1r9hEw')} className="App-button primary">
              Binto Data Pack RP
            </button>
          </li>
          <li>
            <button onClick={() => handleButtonClick('https://cdn.discordapp.com/attachments/1085569148689592383/1126854391014621285/Desktop_2023.07.07_-_14.36.24.04.DVR_Slomo.mp4', 'https://mega.nz/file/Z6wXADRZ#fUOQbbxNduNAakQ2qKEYqEDZPOt730tufum89dzF-J0')} className="App-button primary">
              Binto RP Opti 160 FPS
            </button>
          </li>
          <li>
            <button onClick={() => handleButtonClick('https://cdn.discordapp.com/attachments/1085569148689592383/1126855218290765834/Desktop_2023.07.07_-_14.39.44.05.DVR_Slomo.mp4', 'https://mega.nz/file/NLQFXT7B#P68CyqeMnB3trPS2OlhYxVl79JKs-l93_72uTfybtto')} className="App-button primary">
              Guizou Grey
            </button>
          </li>
          <li>
            <button onClick={() => handleButtonClick('https://cdn.discordapp.com/attachments/1085569148689592383/1126857251869704222/Desktop_2023.07.07_-_14.47.37.06.DVR_Slomo.mp4', 'https://mega.nz/file/cyhn3LLB#cMV5kWdnp1txGEwN1wiqWp3FcBc2hqd-JXsZxMQkzDs')} className="App-button primary">
              Guizou by x2
            </button>
          </li>
          <li>
            <button onClick={() => handleButtonClick('https://cdn.discordapp.com/attachments/1085569148689592383/1126858439033897050/Desktop_2023.07.07_-_14.52.18.07.DVR_Slomo.mp4', 'https://mega.nz/file/c64iWbRB#Tq7kT01sbg9ANuXthAaYQeb41aYVctiii-fm7uQ9tUk')} className="App-button primary">
              Data by x2
            </button>
          </li>
          <li>
            <button onClick={() => handleButtonClick('https://cdn.discordapp.com/attachments/1085569148689592383/1126859577258954802/Desktop_2023.07.07_-_14.56.55.08.DVR_Slomo.mp4', 'https://mega.nz/file/pvhW0ZTZ#2aHDdO0JD5yopRbxXeN8qCO3DeRTXs1_u22F7ssa9xo')} className="App-button primary">
              Data by x2 (Grey / Blue)
            </button>
          </li>
          <li>
            <button onClick={() => handleButtonClick('https://cdn.discordapp.com/attachments/1085569148689592383/1129497274268008578/pack_by_x2_.mp4', 'https://mega.nz/file/JjgBFAwB#Pb07eHA2qmO7yaKsiXkfIaEnrNx68jWB2UaZAI6LG8M')} className="App-button primary">
              Data Gris Rose
            </button>
          </li>
          <li>
            <button onClick={() => handleButtonClick('https://cdn.discordapp.com/attachments/1083378137666179072/1132722720640090202/Desktop_2023.07.23_-_19.10.12.04.DVR_Slomo.mp4', 'https://mega.nz/file/siolBZpb#_6B3I4kELJhTP_yNp06gGvDbygTiQmpP9ArVvijecYU')} className="App-button primary">
              Azure
            </button>
          </li>
          <li>
            <button onClick={() => handleButtonClick('https://media.discordapp.net/attachments/1083378137666179072/1142510733930799155/image.png?width=1202&height=676', 'https://mega.nz/file/MvoEmADL#6BDMZxOs_W-aXdMHNS4T_0pp6TYynB1QP4C3_zbasUg')} className="App-button primary">
              Drare
            </button>
          </li>
          <li>
            <button onClick={() => handleButtonClick('https://media.discordapp.net/attachments/1159214959880831067/1162061608898539601/image.png?ex=653a9135&is=65281c35&hm=aa4649a7c23c8e52e433c794c833ac0ddb1466cd6cbe8fc47b9c0188d18273ba&=&width=1202&height=676', 'https://mega.nz/file/EjAiVa7T#ccfVHh_Qi6pqCTPo6JQo9PrNXh56cbdu-nyHpJPQFmc')} className="App-button primary">
              Nebula
            </button>
          </li>
          <li>
            <button onClick={() => handleButtonClick('https://cdn.discordapp.com/attachments/1083378137666179072/1175898657061605457/2023-11-19_21-38-42.mp4?ex=656ce7f6&is=655a72f6&hm=5b12ceeb01e5d61986162738d084ca25004475a0a7265ebd4d0ebeeba063a041&', 'https://mega.nz/file/07J0CaKT#SqDHkia6zYQE5ejxCrAa98eCdZpoQD451fILSwXmoCw')} className="App-button primary">
              Blue White
            </button>
          </li>
          <li>
            <button onClick={() => handleButtonClick('https://cdn.discordapp.com/attachments/1159214959880831067/1181276582464192644/pack_3.mp4?ex=6580788c&is=656e038c&hm=e9d045d470b3a926f2958fe98f4bec56630c0fe3e9843ae0d5282412f83174a5&', 'https://mega.nz/file/RzZiTZ4D#eLP8T4JgOcoPRD_A-DzXgRM2i3tbCs8Ijpo7t_D5tOs')} className="App-button primary">
              Snow
            </button>
          </li>
        </ul>
        <Modal isOpen={isModalOpen} onRequestClose={closeModal} contentLabel="Video Modal" className="modal-content" overlayClassName="modal-overlay">
          {media && (
            <div className="modal-video">
              <video width="560" height="315" src={media} controls autoPlay></video>
            </div>
          )}
          <div className="modal-actions">
            <a href={downloadUrl} className="modal-button" target="_blank" rel="noopener noreferrer">
              Télécharger
            </a>
            <button className="modal-button" onClick={closeModal}>
              Fermer
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Download1;
