import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
import '../styles/Logout.css';

const Logout = () => {
  cookie.remove('auth-token');

  return (
    <div className="App">
      <header className="App-header">
        <h1>Pack FiveM</h1>
      </header>
      <div className="App-body">
        <p>Vous êtes déconnecté.</p>
        <Link to="/login" className="App-button primary">
          Se connecter
        </Link>
      </div>
    </div>
  );
};

export default Logout;
