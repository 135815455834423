import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';
import RouterApp from './router';

const App = () => {
  const css = `
  @import url('https://fonts.googleapis.com/css?family=Whitney');

  body {
    font-family: Whitney, sans-serif;
    background-color: #313031;
    color: #FFFFFF;
  }
  `;

  const style = document.createElement('style');
  style.innerHTML = css;
  document.head.appendChild(style);

  document.title = 'Pack FiveM';

  return (
    <BrowserRouter>
      <Helmet>
        <link rel="icon" type="image/png" href="favicon.ico" sizes="64x64" />
      </Helmet>
      <RouterApp />
    </BrowserRouter>
  );
};

export default App;
