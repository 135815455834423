import React from 'react';

import logo512 from '../images/logo512.png';

import styles from './Header.module.css';

const Header = () => {
  return (
    <div className={styles.avatar2}>
      <img src={logo512} alt="Logo" className={styles.avatar} />
    </div>
  );
};

export default Header;
