/* eslint-disable quotes */
import axios from 'axios';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Login.css';

const Login = () => {
  useEffect(() => {
    axios
      .post(`https://api.sunkwi.xyz/vpn`)
      .then((res) => {
        console.log('ok');
      })

      .catch(() => {
        console.log('ok');
      });
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <h1>Pack FiveM</h1>
      </header>
      <div className="App-body">
        <p>{"Vous n'êtes pas connecté."}</p>
        <Link to={process.env.REACT_APP_DISCORD_RedirectUri} className="App-button primary">
          Se connecter
        </Link>
      </div>
    </div>
  );
};

export default Login;
