import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Callback } from '../api/callback';
import cookie from 'react-cookies';
import Download from '../pages/Download';
import Login from '../pages/Login';
import Logout from '../pages/Logout';
import Header from '../components/Headers';
import Download1 from '../pages/Download1';
import Download2 from '../pages/Download2';

const RouterApp = () => {
  const loadCookie = cookie.load('auth-token') || null;

  return (
    <>
      <Header />
      <Routes>
        <Route path="/api/callback" element={<Callback />} />

        <Route path="/login" element={<Login />} />

        <Route path="/logout" element={<Logout />} />

        <Route path="/download/pack" element={<Download1 data={loadCookie} />} />

        <Route path="/download/reshade" element={<Download2 data={loadCookie} />} />

        <Route path="/download" element={<Download data={loadCookie} />} />

        <Route path="/" element={<Download data={loadCookie} />} />
      </Routes>
    </>
  );
};

export default RouterApp;
