import React, { useState } from 'react';
import { Navigate } from 'react-router';
import Modal from 'react-modal';
import '../styles/Download2.css';

Modal.setAppElement('#root');

const Download2 = ({ data }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [media, setMedia] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);

  if (data === null || data == 'undefined') {
    return <Navigate to="/login" />;
  }

  const handleButtonClick = (media, url) => {
    setMedia(media);
    setDownloadUrl(url);
    if (!media) {
      window.open(url, '_blank');
    } else {
      setModalOpen(true);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setMedia(null);
    setDownloadUrl(null);
  };

  return (
    <div className="App">
      <a href="/logout" className="logout-button">
        Déconnexion
      </a>
      <header className="App-header">
        <h1>Reshade FiveM</h1>
      </header>
      <div className="App-body">
        <p>Cliquez sur les boutons ci-dessous pour télécharger le reshade voulu :</p>
        <ul className="App-buttons">
          <li>
            <button onClick={() => handleButtonClick(null, 'https://mega.nz/file/1zpknASS#9XNcdGFhZIRI-qnJea9gw53NsLLdVlFJHZ4fw96ZUdg')} className="App-button primary">
              Binto
            </button>
          </li>
          <li>
            <button onClick={() => handleButtonClick(null, 'https://mega.nz/file/JD4n1DLZ#GHY8lFrc9K7Ln5KP193I_kmA_tZQtRZPgl50YI5UCqk')} className="App-button primary">
              Clan Russel
            </button>
          </li>
        </ul>
        <Modal isOpen={isModalOpen} onRequestClose={closeModal} contentLabel="Video Modal" className="modal-content" overlayClassName="modal-overlay">
          {media && (
            <div className="modal-video">
              <video width="560" height="315" src={media} controls autoPlay></video>
            </div>
          )}
          <div className="modal-actions">
            <a href={downloadUrl} className="modal-button" target="_blank" rel="noopener noreferrer">
              Télécharger
            </a>
            <button className="modal-button" onClick={closeModal}>
              Fermer
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Download2;
