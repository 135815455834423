import axios from 'axios';
import { useEffect } from 'react';
import cookie from 'react-cookies';
import { Navigate } from 'react-router';
import './styles.css';

export const Callback = () => {
  const t = new URLSearchParams(window.location.search).get('code');

  useEffect(() => {
    axios
      .get(`https://api.sunkwi.xyz/oauth2/token?code=${t}`)
      .then((res) => {
        if (res.data.access_token === undefined) {
          return (window.location.href = '/login');
        }

        cookie.save('auth-token', res.data.access_token, {
          expires: new Date(new Date().getTime() + +res.data.expires_in + +604800000),
          path: '/',
        });

        return (window.location.href = '/download');
      })

      .catch(() => {
        return <Navigate to="/login" replace={true} />;
      });
  }, []);

  return (
    <>
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '50px', height: '50px', animation: 'spin 1s ease-in-out infinite' }}>
        <div style={{ width: '50px', height: '50px', border: '5px solid orange', borderTopColor: 'transparent', borderRadius: '50%', animation: 'spin 1s ease-in-out infinite' }}></div>
      </div>
      <style>
        {`
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `}
      </style>
    </>
  );
};
