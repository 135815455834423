import React from 'react';
import { Navigate } from 'react-router';
import '../styles/Download.css';

const Download = ({ data }) => {
  if (data === null || data == 'undefined') {
    return <Navigate to="/login" />;
  }

  return (
    <div className="App">
      <a href="/logout" className="logout-button">
        Déconnexion
      </a>
      <header className="App-header">
        <h1>Pack FiveM</h1>
      </header>
      <div className="App-body">
        <p>Cliquez sur la catégorie qui vous intéresse ci-dessous :</p>
        <div className="App-buttons">
          <a href="/download/pack" className="App-button primary">
            Pack
          </a>
          <a href="/download/reshade" className="App-button secondary">
            ReShade
          </a>
        </div>
      </div>
    </div>
  );
};

export default Download;
